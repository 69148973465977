import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { IAppStore } from '../store/appStore.interface';
import { useGetPoductTypesOverviewQuery } from '../api/generated';
import { Box, Button, Container, Divider, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useGlobalStyles } from "../styles/global";
import Header from "../components/Header";
import LoadingContainer from '../components/LoadingContainer';
// import {priceStringWithDuration, priceTotalForDuration} from "../helpers/price";

type ProductTypesProps = {
  store: IAppStore,
}

export default function ProductTypes({ store }: ProductTypesProps) {
  let history = useHistory();

  const classes = {
    ...useGlobalStyles(),
    ...useStyles(),
  };

  if (store.getCurrentProduct().id !== null) {
    store.setCurrentProduct({ id: null });
  }

  const { data, loading, error } = useGetPoductTypesOverviewQuery({
    variables: {
      startDate: store.getStartDate(),
      endDate: store.getEndDate(),
      duration: store.getDuration(),
      locationId: store.getLocation()?.id
    }
  });

  //if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const productTypes = data?.productTypes
    // Do not show products which are not configured for this dateRange / location
    .filter(type => null != type.availabilities_aggregate?.aggregate?.min?.sumAvailableBikes)
    .map(
      type => ({
        id: type.id,
        name: type.name,
        description: type.category,
        longDescription: type.description,
        available: type.availabilities_aggregate?.aggregate?.min?.sumAvailableBikes > 0,
        price: type.prices_aggregate?.aggregate?.min?.minBasePricePerDay,
        image: type.image
      })
    ) ?? [];

  const onSelectProductType = (productTypeId: string) => {
    const currentProduct = store.getCurrentProduct();

    store.setCurrentProduct({
      ...currentProduct,
      id: null,
      data: undefined,
    });

    history.push("/product/" + productTypeId);
  };

  return (
    <>
      <Header store={store} />
      <Container component="main" maxWidth="md">
        <Box className={classes.paper}>
          {loading ? (
            <LoadingContainer />
          ) : (
            <>
              <Typography variant="h1">
                Produkt auswählen
              </Typography>

              {productTypes.map(type => (
                <Box key={type.id}>
                  <Grid
                    container
                    spacing={3}
                    className={type.available ? classes.enabled : classes.disabled}
                  >
                    <Grid item xs={5}>
                      <img className={classes.productTypeThumbnail} src={type.image} alt={type.name} />
                    </Grid>

                    <Grid item xs={7}>
                      {/*<Hidden xsDown>*/}
                      {/*  <Typography variant={"h5"} className={classes.productTitle}>{type.description}</Typography>*/}
                      {/*  <Typography variant={"subtitle2"} dangerouslySetInnerHTML={{ __html: type.longDescription }} />*/}
                      {/*</Hidden>*/}
                        <Typography variant={"h5"} className={classes.productTitle}>{type.description}</Typography>
                        <Typography variant={"body2"} className={classes.productSubtitle}>{type.name}</Typography>
                        <Typography variant={"body2"}>{type.longDescription}</Typography>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      disabled={!type.available}
                      className={classes.productSelectButton}
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => onSelectProductType(type.id)}
                    >
                      {
                        type.available ? (
                          <>
                            Reservieren
                            {/*ab {priceStringWithDuration(priceTotalForDuration(type.price ?? 0, store.getDuration()), store.getDuration())}*/}
                          </>
                        ) : (
                          <>Ausgebucht</>
                        )
                      }
                    </Button>
                  </Grid>

                  <Divider className={classes.divider} />
                </Box>
              ))}
            </>
          )}

        </Box>
      </Container>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      margin: theme.spacing(6, 0, 3),
    },
    productTypeThumbnail: {
      width: '100%',
      height: 'auto',
    },
    enabled: {},
    disabled: {
      opacity: 0.6,
      filter: 'grayscale(100%)',
    },
    productSelectButton: {
      marginTop: theme.spacing(1),
    },
    productTitle: {
      margin: 0,
      padding: 0,
    },
    productSubtitle: {
      marginBottom: '8px',
    },
  }),
)