import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

export const useGlobalStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      paddingBottom: '50px',
      '& .MuiTextField-root': {
        marginBottom: theme.spacing(1),
      },
      marginBottom: theme.spacing(10),
    },
    appBar: {
      top: 'auto',
      bottom: 0,
      '& .MuiButtonBase-root': {
        marginTop: '1em',
      },
      padding: theme.spacing(2),
      paddingTop: 0,
      backgroundColor: '#ffffff',
    },
    divider: {
      marginTop: '2em',
      marginBottom: '2em'
    },
    primaryButton: {
      height: '4em',
    },
  }),
);