import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AppBar } from "@material-ui/core";

import { IAppStore } from '../store/appStore.interface';
import { useGlobalStyles } from '../styles/global';
import MiniBasket from './MiniBasket';

type HeaderProps = {
  store: IAppStore,
}

export default function Header({ store }: HeaderProps) {
  const classes = {
    ...useGlobalStyles(),
    ...useStyles(),
  };

  return (
    <>
      {store.getLocation()?.id && (
        <AppBar position="relative" color="default" className={classes.appBar}>
          <MiniBasket store={store} />
        </AppBar>
      )}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      top: 0,
      backgroundColor: '#ffffff',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: '4em',
      marginBottom: '2em',
    },
  }),
);
