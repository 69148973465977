import React, { useEffect, useState } from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {
  Box, Button,
  Container,
  Typography
} from "@material-ui/core";
import {useHistory} from 'react-router-dom';

import { useGetLocationsQuery } from "../api/generated";
import { IAppStore, ILocation } from "../store/appStore.interface";
import Header from "../components/Header";
import {useGlobalStyles} from "../styles/global";
// import LocationSelector from '../components/LocationSelector';
import Footer from '../components/Footer';
import LocationSelector from '../components/LocationSelector';

type LocationProps = {
  store: IAppStore,
}

export default function Start({store}: LocationProps) {
  let history = useHistory();
  const [location, setLocation] = useState<ILocation | undefined>(store.getLocation());

  const locationSearch = new URLSearchParams(window.location.search);
  if (location && locationSearch.get('locationId')) {
    store.setLocation(location)
    history.push('/date-range');
  }

  const classes = {
    ...useGlobalStyles(),
    ...useStyles(),
  };

  /**
   * GraphQL
   */
  const {data: locationsData, loading: locationsLoading, error: locationsError} = useGetLocationsQuery();
  const locations = locationsData?.locations ?? [];

  useEffect(() => {
    if (1 === locations.length) {
      setLocation(locations[0]);
      store.setLocation(locations[0]);
    }
  }, [locations, store])

  if (locationsLoading || locationsError) {
    return <></>
  }

  /**
   * Handlers
   */
  const handleConfirmLocation = (location: ILocation) => {
    setLocation(location);

    store.setLocation(location)
    history.push('/date-range');
  };

  const handleSubmitLocation = () => {
    if (location) {
      store.setLocation(location)
      history.push('/date-range');
    }
  }

  return (
    <>
      <Header store={store} />
      <Container component="main" maxWidth="md">
        <Box className={classes.paper}>
          <Box className={classes.content}>
            <Box className={classes.header}>
              <img
                className={classes.headerImage}
                src={'/theme/img/logo.png'}
                alt={'Logo'}
              />
            </Box>
            <Typography variant="h1" className={classes.headerText}>
              Bikes & Ausrüstung reservieren
            </Typography>
            {locations.length > 1 ? (
              <>
                <Typography variant="h2">
                  Wo möchtest du starten?
                </Typography>
                <Typography variant="subtitle1">
                  Wähle einen unserer Standorte, an dem du dein Bike reservieren und abholen möchtest.
                </Typography>
              </>
            ) : (
              <>
                Willkommen bei unserer Online-Reservierung.<br />
                Klicke "Start", um deine Aufenthaltsdauer und aus unseren Angeboten zu wählen.
              </>
            )}

            <LocationSelector
              activeLocation={location}
              locations={locations}
              onSelect={handleConfirmLocation}
            />
          </Box>
        </Box>
      </Container>

      {
        location
        && (
          <Footer store={store}>
            <>
              <Button
                className={classes.primaryButton}
                variant="contained"
                color="primary"
                onClick={() => handleSubmitLocation()}
              >
                {locations.length > 1 ? (
                  <>Standort ({location.name}) wählen</>
                ) : (
                  <>Start</>
                )}

              </Button>
            </>
          </Footer>
        )
      }
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      textAlign: 'center',
      width: '100%',
    },
    header: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      margin: '0 auto',
    },
    headerImage: {
      width: '60%',
    },
    headerText: {
      padding: '0.5em 0',
      textTransform: 'uppercase'
    }
  }),
);
